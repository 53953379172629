
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import { IceButtonTab } from '@icepanel/component-lib'
import { DiagramType, StatsDetails, StatsPeriods, StatsUser } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { RecycleScroller } from 'vue-virtual-scroller'
import { getModule } from 'vuex-module-decorators'

import TableEmptyState from '@/components/table/empty-state.vue'
import TableHeadItem, { ITableHeadItem } from '@/components/table/head-item.vue'
import TableRowItem from '@/components/table/row-item.vue'
import TableSearchInput from '@/components/table/search-input.vue'
import { getDateKeys } from '@/helpers/date-keys'
import { prefixEmoji } from '@/helpers/emojis'
import getColor from '@/helpers/get-color'
import { AlertModule } from '@/modules/alert/store'
import DiagramThumbnailMenu from '@/modules/diagram/components/thumbnail-menu.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { OverviewModule } from '@/modules/overview/store'
import { ShareModule } from '@/modules/share/store'
import getInitials from '@/modules/user/helpers/get-initials'
import { VersionModule } from '@/modules/version/store'

import { FlowModule } from '../store'
import FlowContextMenu from './flow-context-menu/index.vue'
import ThumbnailMenu from './thumbnail-menu.vue'

export interface IFlowListItemContributor {
  color: string
  id: string
  initials: string
  name: string
  updatedAt: string
}

export interface IFlowListItem {
  contextMenuOpen: (event: PointerEvent) => void
  contributors: IFlowListItemContributor[]
  diagramClick: () => void
  diagramId: string
  diagramName: string
  domainName: string
  edits?: StatsDetails
  flowClick: () => void
  handleId: string
  id: string
  lastEdit?: string
  level: number
  name: string
  type: DiagramType
  views?: StatsDetails
}

const LEVEL_NUMBER: Record<DiagramType, number> = {
  'app-diagram': 2,
  'component-diagram': 3,
  'context-diagram': 1
}

@Component({
  components: {
    DiagramThumbnailMenu,
    FlowContextMenu,
    RecycleScroller,
    TableEmptyState,
    TableHeadItem,
    TableRowItem,
    TableSearchInput,
    ThumbnailMenu
  },
  name: 'FlowsTable'
})
export default class FlowsTable extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  overviewModule = getModule(OverviewModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  searchModel = ''

  hoverItemId: string | null = null
  hoverItemRef: HTMLElement | null = null

  hoverDiagramItemId: string | null = null
  hoverDiagramItemRef: HTMLElement | null = null

  tabChanged = false
  tabChangedTimer?: number

  contextMenuOpen = false
  contextMenuId: string | null = null
  contextMenuActivatorRef: HTMLElement | null = null

  @Ref() readonly contextMenuRef?: FlowContextMenu
  @Ref() readonly contextMenuButtonRefs!: HTMLElement[]

  get sort () {
    return this.$queryValue('sort') ?? 'asc'
  }

  get sortedColumn () {
    return this.$queryValue('sorted_column') ?? 'lastEdit'
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get search () {
    return this.$queryValue('flow_search') || ''
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get domainNamePrefixEmoji () {
    return prefixEmoji(this.currentDomain?.name)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape?.organizationId)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get flowsLimitReached () {
    return this.flows.length >= this.currentOrganizationLimits.flows
  }

  get contextMenuFlow () {
    return this.contextMenuId ? this.flowModule.flows[this.contextMenuId] : undefined
  }

  get timelineTabs (): IceButtonTab[] {
    return this.overviewModule.timelineTabs
  }

  get selectedTimeline () {
    return this.$queryValue('timeline') ?? localStorage.getItem('flowsSelectedTimelineTab') ?? 'week'
  }

  get authorizedToEdit () {
    return this.currentOrganizationPermission === 'admin' || this.currentOrganizationPermission === 'write'
  }

  get actionsEnabled () {
    return !this.currentShareLink && this.currentVersion?.tags.includes('latest')
  }

  get loading () {
    return !this.currentShareLink && (
      this.versionModule.travelling ||
      !this.landscapeModule.landscapesListStatus.success ||
      !this.organizationModule.organizationsListStatus.success ||
      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) ||
      (this.domainModule.domainsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.domainModule.domainsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.diagramModule.diagramsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.diagramModule.diagramsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.flowModule.flowsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.flowModule.flowsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.modelModule.objectsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.modelModule.objectsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get progressBarEnabled () {
    return this.searchModel !== this.search || this.tabChanged
  }

  get tableHeadItems (): ITableHeadItem[] {
    return [
      { id: 'name', title: 'Flow name', width: '20%' },
      { id: 'domain', title: 'Domain', width: '20%' },
      { id: 'diagram', title: 'In diagram', width: '20%' },
      { id: 'contributors', title: 'Contributors', width: '10%' },
      { id: 'lastEdit', title: 'Last edit', width: '8%' },
      { id: 'views', title: 'Views', width: '8%' },
      { id: 'edits', title: 'Edits', width: '8%' },
      { id: 'actions', sortable: false, title: '', width: '6%' }
    ]
  }

  get flows () {
    return Object
      .values(this.flowModule.flows)
      .filter(o => {
        const diagram = this.diagramModule.diagrams[o.diagramId]
        const model = diagram ? this.modelModule.objects[diagram.modelId] : undefined
        if (model && diagram.status !== 'current') {
          return false
        } else if (model && this.currentDomain && this.currentDomain.id !== model.domainId) {
          return false
        } else {
          return true
        }
      })
  }

  get items () {
    if (this.loading) {
      return []
    }
    return this.flows.map((o): IFlowListItem => {
      const diagram = this.diagramModule.diagrams[o.diagramId]
      const diagramObject = diagram ? this.modelModule.objects[diagram.modelId] : undefined
      const diagramDomain = diagramObject ? this.domainModule.domains[diagramObject.domainId] : undefined

      const editStats = this.countStats(o.stats.edits)
      const viewStats = this.countStats(o.stats.views)

      const contributors = Object
        .values(editStats.users)
        .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
        .map(o => ({
          color: TAG_COLOR_ACTIVE[getColor(o.id)],
          id: o.id,
          initials: o.name ? getInitials(o.name) : '',
          name: o.name ?? '',
          updatedAt: o.updatedAt
        }))
        .filter(o => o && o.name)

      return {
        contextMenuOpen: (event: PointerEvent) => {
          const contextMenuOpen = this.contextMenuId === o.id ? !this.contextMenuOpen : true
          setTimeout(() => {
            this.contextMenuOpen = contextMenuOpen
            this.contextMenuId = o.id
            this.contextMenuActivatorRef = event.target as HTMLElement | null
          })
        },
        contributors,
        diagramClick: () => this.$router.push({
          name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
          params: {
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          },
          query: {
            diagram: diagram?.handleId,
            model: diagramObject?.handleId
          }
        }),
        diagramId: o.diagramId,
        diagramName: diagram?.name || 'Diagram',
        domainName: diagramDomain?.name || 'Domain',
        edits: editStats,
        flowClick: () => this.$router.push({
          name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
          params: {
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          },
          query: {
            diagram: diagram?.handleId,
            flow: o.handleId,
            flow_path: undefined,
            flow_step: undefined,
            model: diagramObject?.handleId
          }
        }),
        handleId: o.handleId,
        id: o.id,
        lastEdit: contributors[0]?.updatedAt,
        level: diagram ? LEVEL_NUMBER[diagram.type] ?? 0 : 0,
        name: o.name,
        type: diagram?.type || 'context-diagram',
        views: viewStats
      }
    })
  }

  get sortedItems () {
    return this.items.sort((a, b) => {
      let sortValueA: any
      let sortValueB: any

      if (this.sortedColumn === 'name') {
        sortValueA = a.name
        sortValueB = b.name
      } else if (this.sortedColumn === 'domain') {
        sortValueA = a.domainName
        sortValueB = b.domainName
      } else if (this.sortedColumn === 'diagram') {
        sortValueA = a.diagramName
        sortValueB = b.diagramName
      } else if (this.sortedColumn === 'lastEdit') {
        sortValueA = b.lastEdit ?? ''
        sortValueB = a.lastEdit ?? ''
      } else if (this.sortedColumn === 'contributors') {
        sortValueA = a.contributors.length
        sortValueB = b.contributors.length
      } else if (this.sortedColumn === 'views') {
        sortValueA = a.views?.count ?? 0
        sortValueB = b.views?.count ?? 0
      } else if (this.sortedColumn === 'edits') {
        sortValueA = a.edits?.count ?? 0
        sortValueB = b.edits?.count ?? 0
      } else {
        if (a.diagramId === b.diagramId) {
          if (a.name === b.name) {
            return a.handleId > b.handleId ? 1 : -1
          } else {
            return a.name > b.name ? 1 : -1
          }
        } else {
          return a.diagramId < b.diagramId ? 1 : -1
        }
      }

      if (sortValueA === sortValueB) {
        return 0
      } else if (sortValueA > sortValueB) {
        return this.sort === 'asc' ? 1 : -1
      } else {
        return this.sort === 'asc' ? -1 : 1
      }
    })
  }

  get filteredItemsFuzzy () {
    return new Fuse(this.sortedItems, {
      keys: [
        'name',
        'domainName',
        'diagramName'
      ],
      threshold: 0.3
    })
  }

  get filteredItems () {
    return this.search ? this.filteredItemsFuzzy.search(this.search).map(o => o.item) : this.sortedItems
  }

  @Watch('filteredItems')
  async onFilteredItemsChanged () {
    if (this.contextMenuId) {
      await this.$nextTick()
      this.contextMenuActivatorRef = this.contextMenuButtonRefs.find(o => o.id === `context-menu-button-${this.contextMenuId}`) ?? null
      await this.$nextTick()
      this.contextMenuRef?.menuRef.updateDimensions()
    }
  }

  @Watch('contextMenuId')
  async onContextMenuIdChanged () {
    if (this.contextMenuId) {
      await this.$nextTick()
      this.contextMenuRef?.menuRef.updateDimensions()
    }
  }

  @Watch('search')
  onSearchChange (newVal: string) {
    if (newVal !== this.searchModel) {
      this.searchModel = newVal
    }
  }

  mounted () {
    if (this.search) {
      this.searchModel = this.search
    }
  }

  destroyed () {
    clearTimeout(this.tabChangedTimer)
  }

  updateQueryDebounce = debounce(this.updateQuery.bind(this), 500, {
    leading: false,
    trailing: true
  })

  async updateQuery () {
    const query: any = {}
    if (this.search !== this.searchModel) {
      query.flow_search = this.searchModel || undefined
    }
    await this.$replaceQuery(query)
  }

  selectTimeline (timeline: string) {
    this.tabChanged = true
    clearTimeout(this.tabChangedTimer)
    this.tabChangedTimer = window.setTimeout(() => {
      this.tabChanged = false
    }, 600)

    this.$replaceQuery({
      timeline
    })

    localStorage.setItem('flowsSelectedTimelineTab', timeline)
  }

  async sortColumn (id: string) {
    const query: any = {}

    if (this.sortedColumn === id) {
      query.sort = this.sort === 'asc' ? 'desc' : this.sort === 'desc' ? undefined : 'asc'
    } else {
      query.sort = 'asc'
    }

    if (query.sort) {
      query.sorted_column = id
    } else {
      query.sorted_column = undefined
    }

    await this.$replaceQuery(query)
  }

  getLastUser (users: Record<string, StatsUser>) {
    return Object.values(users).reduce((p, c) => !p || c.updatedAt > p.updatedAt ? c : p, null as StatsUser | null)
  }

  countStats (periods: StatsPeriods): StatsDetails {
    const { monthStartAtKey, todayStartAtKey, weekStartAtKey, yesterdayStartAtKey } = getDateKeys()

    const statsDefault: StatsDetails = { count: 0, users: {} }

    switch (this.selectedTimeline) {
      case 'today':
        return periods.day[todayStartAtKey] ?? statsDefault
      case 'yesterday':
        return periods.day[yesterdayStartAtKey] ?? statsDefault
      case 'week':
        return periods.week[weekStartAtKey] ?? statsDefault
      case 'month':
        return periods.month[monthStartAtKey] ?? statsDefault
      default:
        return periods.all
    }
  }
}
